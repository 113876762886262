import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "python"
    }}>{`Python`}</h1>
    <h2 {...{
      "id": "quickstart"
    }}>{`Quickstart`}</h2>
    <h3 {...{
      "id": "set-up-a-ghostport-project"
    }}>{`Set up a GhostPort project`}</h3>
    <p>{`Follow the instructions from the `}<a parentName="p" {...{
        "href": "/"
      }}>{`Getting Started`}</a>{` page.`}</p>
    <h3 {...{
      "id": "install-the-ghostport-pip-module"
    }}>{`Install the GhostPort PIP Module`}</h3>
    <p>{`Install the `}<a parentName="p" {...{
        "href": "https://pypi.org/project/ghostport/"
      }}><inlineCode parentName="a">{`ghostport`}</inlineCode></a>{` PIP module.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pip install ghostport
`}</code></pre>
    <h3 {...{
      "id": "initialize-the-client"
    }}>{`Initialize the Client`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from ghostport import GhostPort

client = GhostPort('YOUR_TOKEN')
`}</code></pre>
    <h3 {...{
      "id": "get-all-keys-and-values"
    }}>{`Get all keys and values`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`flags = client.get_flag_values()

print(flags) # Prints out a dictionary of flag keys to values
`}</code></pre>
    <h3 {...{
      "id": "get-a-flags-values"
    }}>{`Get a flag's values`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`value = client.get_flag_value('doTheThing')

print(value) # Prints out the value of the flag
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      